import React, { useLayoutEffect, useState } from 'react';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { useMutation } from 'react-query';
import { graphql, navigate } from 'gatsby';

import {
    layout,
    header,
    title,
    columnFilter,
    listing,
    listingContainer,
} from './product-listing-page.module.scss';

import pagesContext from '../config/pages-context';
import { IPage } from '../models/page.model';
import { getProducts } from '../api-ssr/products';
import useQueryParam from '../hooks/use-query-param';

import MainLayout from '../layouts/main-layout';
import Listing from '../components/organisms/listing';
import ProductCard from '../components/molecules/product-card';
import BlockHeader from '../components/molecules/block-header';
import ColumnFilter from '../components/molecules/column-filter';
import Loader from '../components/atoms/loader';

interface IProductListingPage {
    readonly data: {
        page: IPage | null;
    };
}

const PER_PAGE = 12;

const ProductListingPage: React.FC<IProductListingPage> = ({ data }) => {
    const { t, language } = useI18next();
    const { page } = data;
    const pageQuery = useQueryParam('page');
    const pageIndex = pageQuery.liveValues[0] || '1';
    const [productsData, setProductsData] = useState([]);
    const [productsCount, setProductsCount] = useState(0);
    const [listingConfig, setListingConfig] = useState({
        columns: 4,
        isWide: false,
    });

    const productsMutation = useMutation(() => getProducts(pageIndex, PER_PAGE.toString(), language), {
        onSuccess: ({ data }) => {
            if (Number(pageIndex) > data.pagination.pageCount) {
                navigate(`/${pagesContext.productListing.slug}/?page=${data.pagination.pageCount}`);
            } else {
                setProductsData(data.items);
                setProductsCount(data.pagination.totalCount);
            }
        },
        onError: () => {
            alert(t('api.error'));
        },
    });

    const { isSuccess } = productsMutation;

    useLayoutEffect(() => {
        productsMutation.mutate();
    }, [pageIndex]);

    function handleClick(columns: number, wide: boolean) {
        setListingConfig({ columns: columns, isWide: wide });
    }

    return (
        <MainLayout
            className={layout}
            page={page}
            showRecipesSlider={false}
            includeHeaderPadding={true}
        >
            <div className={header}>
                <BlockHeader className={title} titleText={t('product.listing.title')} />
                <ColumnFilter
                    className={columnFilter}
                    config={listingConfig}
                    handleClick={handleClick}
                />
            </div>
            <div className={listingContainer}>
                {!isSuccess && <Loader within={true} />}
                <Listing
                    className={listing}
                    columns={listingConfig.columns}
                    largeGaps={true}
                    itemIdKey={'productId'}
                    ItemComponent={ProductCard}
                    itemProps={{
                        fullWidth: listingConfig.isWide,
                    }}
                    data={productsData}
                    paginationConfig={{
                        links: createPaginationLinks(productsCount, PER_PAGE),
                        activeIndex: Number(pageIndex) - 1,
                    }}
                />
            </div>
        </MainLayout>
    );
};

const createPaginationLinks = (productsCount: number, perPage: number) => {
    const pagesCount = Math.ceil(productsCount / perPage);
    return Array.from(Array(pagesCount).keys()).map((_page, index) => {
        return {
            to: `/${pagesContext.productListing.slug}/`,
            params: [
                {
                    key: 'page',
                    value: (index + 1).toString(),
                },
            ],
        };
    });
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default ProductListingPage;
