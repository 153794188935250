import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import pagesContext from '../../config/pages-context';
import { IProductDetailsSimple } from '../../models/product-details';

import ListingCard from './listing-card';

interface IProductCardProps {
    data: IProductDetailsSimple;
    fullWidth?: boolean;
}

const ProductCard: React.FC<IProductCardProps> = ({ data, fullWidth = false }) => {
    const { t } = useI18next();

    return (
        <ListingCard
            data={{
                title: data.name,
                redirectLink: `${pagesContext.productDetails.slug}/`,
                params: [{ key: 'n', value: data.slug }],
                buttonText: t('product.listing.button'),
                src: data.media[0].url,
                imageAlt: t('product.listing.image.alt'),
            }}
            showSummary={false}
            fullWidth={fullWidth}
        />
    );
};

export default ProductCard;
