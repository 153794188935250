import axios from 'axios';
import prependApiUrl from '../utils/prepend-api-url';

export const getProducts = (index = '1', perPage = '12', language?: string) => {
    return axios.get(prependApiUrl(`/products?expand=media&per-page=${perPage}&page=${index}${language ? `&lang=${language}` : ''}`), {});
};

export const getProduct = (slug: string, language?: string) => {
    return axios.get(prependApiUrl(`/products/${slug}?expand=nutritions,media${language ? `&lang=${language}` : ''}`), {});
};
