import React from 'react';

import { overlay, within as withinClass } from './loader.module.scss';

import LoaderSVG from '../../assets/images/svg/loader.svg';

interface ILoaderProps {
    within?: boolean;
}

const Loader: React.FC<ILoaderProps> = ({ within = false }) => {
    return (
        <div className={`${overlay} ${within ? withinClass : ''}`}>
            <LoaderSVG />
        </div>
    );
};

export default Loader;
